import { Platform } from 'react-native';
import Cookies from 'js-cookie';
import { user_key_name, dotnet_cookie_name } from './constants';
import AsyncStorage from '@react-native-async-storage/async-storage';

const isWeb = Platform.OS === 'web';
const isNative = !isWeb;

const originalFetch = global.fetch;

const trySetCookiesFromStorage = async () => {
  let storedCookies = await AsyncStorage.getItem(dotnet_cookie_name);
  if(!storedCookies){
    return;
  }
  storedCookies = JSON.parse(storedCookies);
  // matches logic from dotnet auth cookie settings
  const cookieOptions = {
    secure: true,
    sameSite: 'None',
    path: '/',
    expires: 90
  };
  if(window.location.hostname !== 'localhost'){
    cookieOptions.domain = '.nectarnow.com';
    cookieOptions.sameSite = 'Lax';
  }
  storedCookies.forEach(c => {
    Cookies.set(c.key, c.value, cookieOptions);
  });
};


export const customFetch = async (url, options = {}) => {
  try {
    let storedCookies = await AsyncStorage.getItem(dotnet_cookie_name);
    if(isNative && storedCookies){
      storedCookies = JSON.parse(storedCookies);
      options.headers = {
        'Cookie': storedCookies.map(c => `${c.key}=${c.value}`).join('; ')
      };
      options.credentials = 'omit'; // 'include' can interfere with the cookies we just set manually in the headers
    }

    const response = await originalFetch(url, options);
    
    if (response.status === 401) {
      if(isWeb){
        await trySetCookiesFromStorage();
        const responseRetry = originalFetch(url, options);
        if (responseRetry.status !== 401) {
          return responseRetry;
        } else {
          await AsyncStorage.removeItem(dotnet_cookie_name);
          Cookies.get().forEach(c => Cookies.remove(c));
        }
      } else {
        await AsyncStorage.removeItem(dotnet_cookie_name);
        Cookies.get().forEach(c => Cookies.remove(c));
      }
      await AsyncStorage.removeItem(user_key_name);
      if(isWeb){
        window.location.href = '/';
      } else {
        // TODO: redirect to landing page
      }
    }
    
    return response;
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
};
