import * as WebBrowser from 'expo-web-browser';
import { makeRedirectUri } from 'expo-auth-session';
import { Platform } from 'react-native';
import { API_URL, CHANGE_PASSWORD_POLICY_NAME, CHANGE_USERNAME_POLICY_NAME, CHANGE_PHONENUMBER_POLICY_NAME } from "react-native-dotenv";

const SignInUp = async () => {
  if (Platform.OS === 'web') {
    window.location.href = `${API_URL}/api/auth/login?returnUrl=${encodeURIComponent(window.location.href)}&NetworkSubdomain=${window.location.hostname.split('.')[0]}`;
  } else {
    // const redirectUri = makeRedirectUri({ 
    //   scheme: 'com.nectarnow',
    //   path: 'auth'
    // });
    // const result = await WebBrowser.openAuthSessionAsync(
    //   `${API_URL}/api/auth/login?returnUrl=${encodeURIComponent(redirectUri)}`,
    //   redirectUri
    // );
  }
};

const SignOut = async () => {
  if (Platform.OS === 'web') {
    window.location.href = `${API_URL}/api/auth/logout?returnUrl=${encodeURIComponent(window.location.origin)}`;
  } else {
    // const response = await fetch(`${API_URL}/api/auth/logout?returnUrl=${encodeURIComponent(window.location.origin)}`, { 
    //   credentials: 'include'
    // });
    
    // if (response.ok) {
    //   handleLogoutForTranslation();
    // }
  }
};

const initiateChange = async (policy) => {
  if (Platform.OS === 'web') {
    window.location.href = `${API_URL}/api/auth/change?policy=${policy}&returnUrl=${encodeURIComponent(window.location.href)}`;
  } else {
    // const result = await WebBrowser.openAuthSessionAsync(
    //   `${API_URL}/api/auth/change?returnUrl=${encodeURIComponent(window.location.href)}&policy=${policy}`,
    //   redirectUri
    // );

    // if (result.type === 'success') {
    //   console.log('Change Successful', result);
    // }
  }
};

const ChangePassword = () => initiateChange(CHANGE_PASSWORD_POLICY_NAME);
const ChangeUsername = () => initiateChange(CHANGE_USERNAME_POLICY_NAME);
const ChangePhoneNumber = () => initiateChange(CHANGE_PHONENUMBER_POLICY_NAME);

export { SignInUp, SignOut, ChangePassword, ChangeUsername, ChangePhoneNumber };