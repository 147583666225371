import AsyncStorage from '@react-native-async-storage/async-storage';
import { jwtDecode } from 'jwt-decode';
import { API_URL, APPLICATION_SERVER_KEY } from "react-native-dotenv"

const GetNeighbor = async () => {
    try {
        const response = await fetch(`${API_URL}/api/Neighbor/LoggedInNeighbor`, {
            method: 'GET',
            credentials: 'include'
        });
    
        if (!response.ok)
            throw `API response ${response.status} ${response.statusText}`
    
        return await response.json();
    } catch (error) {
        console.error('ERROR:', error);
        return null;
    }
}

const UpdateNeighbor = async (neighbor) => {
    try {
        const response = await fetch(`${API_URL}/api/Neighbor/${neighbor.neighborId}`, {
            method: 'POST',
            credentials: 'include',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(neighbor)
        });
    
        if (!response.ok)
            throw `API response ${response.status} ${response.statusText}`
    
        return neighbor;
    } catch (error) {
        console.error('ERROR:', error);
        return null;
    }
}

const updateDatabaseWithPushSubscription = async (neighbor, subscription = null) => {
    let updatedNeighbor = structuredClone(neighbor);   
    if (subscription) {
        const subscriptionWithKeys = JSON.parse(JSON.stringify(subscription));
        updatedNeighbor.sendPushInd = true;
        updatedNeighbor.pushEndpoint = subscriptionWithKeys.endpoint;
        updatedNeighbor.pushAuth = subscriptionWithKeys.keys.auth;
        updatedNeighbor.pushP256DH = subscriptionWithKeys.keys.p256dh;
    } else {
        updatedNeighbor.sendPushInd = false;
        updatedNeighbor.pushEndpoint = null;
        updatedNeighbor.pushAuth = null;
        updatedNeighbor.pushP256DH = null;
    }

    if (updatedNeighbor.sendPushInd === neighbor.sendPushInd &&
        updatedNeighbor.pushEndpoint === neighbor.pushEndpoint &&
        updatedNeighbor.pushAuth === neighbor.pushAuth &&
        updatedNeighbor.pushP256DH === neighbor.pushP256DH    
    )
        return console.info('No subscription update needed');

    await UpdateNeighbor(updatedNeighbor);
}

const updatePushSubscription = async () => {
    let registration = await navigator.serviceWorker.getRegistration();
    if (!registration)
        return;

    let neighbor = null;
    try {
        neighbor = await GetNeighbor();
    } catch (error) {
        console.error('ERROR:', error);
    }
    if (!neighbor)
        return;

    let subscription = await registration.pushManager.getSubscription();

    if (!neighbor.sendPushInd) {
        subscription && await subscription.unsubscribe();
        return console.info('Silently Unsubscribed');
    }

    if (Notification.permission !== 'granted') {
        subscription && await subscription.unsubscribe();
        await updateDatabaseWithPushSubscription(neighbor, null);
        return console.info('Silently Toggled OFF Push Notifications and Unsubscribed');
    }

    if (!subscription)
        subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: APPLICATION_SERVER_KEY
        });

    await updateDatabaseWithPushSubscription(neighbor, subscription);
    return console.info('Silently Subscribed');
}

export { GetNeighbor, UpdateNeighbor, updatePushSubscription };