import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { API_URL } from 'react-native-dotenv';

const SignalRContext = createContext(null);

export const SignalRProvider = ({ children }) => {
    const [connection, setConnection] = useState(null);
    const [latestSignalRMessage, setLatestSignalRMessage] = useState(null);
    const [signalRMessages, setSignalRMessages] = useState([]);
    const { user } = useAuth();
    const isLoggedIn = !!user;

    useEffect(() => {
        const Connect = async () => {
            let response = await fetch(`${API_URL}/api/Auth/token?oid=${user.oid}`);
            let token = await response.json();
            
            let connectionBuilder = new HubConnectionBuilder()
                .withUrl(`${API_URL}/notificationhub`, { accessTokenFactory: () => token })
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Information)
                .build();

            await connectionBuilder.start();

            connectionBuilder.on("ReceiveNotification", (message) => {
                setLatestSignalRMessage(message);
            })

            connectionBuilder.onclose((error) => {
                console.Error('ERROR: connection to hub has been closed!', error);
            })

            setConnection(connectionBuilder);
        }

        /*
        if (isLoggedIn)
            Connect();
        */
    }, [isLoggedIn]);

    useEffect(() => {
        if (latestSignalRMessage != null)
            setSignalRMessages([...signalRMessages, latestSignalRMessage])
    }, [latestSignalRMessage])

    const SendSignalRMessage = async (message) => {
        if (connection == null)
            return console.error('ERROR: hub connection is null!')

        if (connection.state !== 'Connected')
            return console.error('ERROR: disconnected from hub!')

        try {
            let recipientId = message.recipientMessages[0].receiverNetworkId;
            await connection.invoke("SendNotificationToNetwork", recipientId, message);
        } catch (error) {
            console.error('ERROR: could not send message to hub!', error);
        }
    }

    return (
        <SignalRContext.Provider value={{
            connection,
            latestSignalRMessage,
            signalRMessages,
            SendSignalRMessage
        }}>
            {children}
        </SignalRContext.Provider>
    );
}

export const useSignalR = () => useContext(SignalRContext);