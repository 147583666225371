import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SignInUp, SignOut, ChangePassword, ChangeUsername, ChangePhoneNumber } from '../utils/Auth_utils';
import { API_URL } from "react-native-dotenv";
import { Platform } from 'react-native';
import { getNewTranslateData } from '../pageTranslation';
import { user_key_name, dotnet_cookie_name } from '../utils/constants';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPerformingAuthAction, setIsPerformingAuthAction] = useState(false);

  useEffect(() => {
    const afterLoginSuccessfulOnWeb = async () => {
      if (Platform.OS === 'web') {
        // service worker push notifications for web
        // const registration = await navigator.serviceWorker.getRegistration();
        // if (registration) {
        //   registration.active.postMessage({
        //     ApiUri: API_URL,
        //   });
        // }
        getNewTranslateData();

        const response = await fetch(`${API_URL}/api/auth/WebGetCookie`, { credentials: 'include' });
        const authCookies = await response.json();
        if (authCookies && authCookies.some(c => c.key === 'NectarAuth')) {
          await AsyncStorage.setItem(dotnet_cookie_name, JSON.stringify(authCookies));
        }
      }
    }

    const loadUser = async () => {
      try {
        const storedClaims = await AsyncStorage.getItem(user_key_name);
        if (storedClaims && storedClaims.length) {
          setUser(JSON.parse(storedClaims));
          await afterLoginSuccessfulOnWeb();
        } else {
          const response = await fetch(`${API_URL}/api/auth/getUserInfo`, { credentials: 'include' });
          const userData = await response.json();
          if (userData.isAuthenticated) {
            setUser(userData.claims);
            await AsyncStorage.setItem(user_key_name, JSON.stringify(userData.claims));
            await afterLoginSuccessfulOnWeb();
          }
        }
      } catch (error) {
        console.error('Error loading user:', error);
      } finally {
        setIsLoading(false);
      }
    };
    loadUser();
  }, []);

  const clearUserCache = async () => {
    await AsyncStorage.removeItem(user_key_name);
    await AsyncStorage.removeItem(dotnet_cookie_name);
  }

  const login = async () => {
    setIsPerformingAuthAction(true);
    const userData = await SignInUp();
    if (userData) {
      setUser(userData);
      await AsyncStorage.setItem(user_key_name, JSON.stringify(userData));
    }
    setIsPerformingAuthAction(false);
  };

  const logout = async () => {
    setIsPerformingAuthAction(true);
    setUser(null);
    await clearUserCache();
    await SignOut();
    setIsPerformingAuthAction(false);
  };

  const changePassword = async () => {
    const success = await ChangePassword();
    if (success) {
      // Optionally update user state if needed for native, web will have redirected by now
    }
    return success;
  };

  const changeUsername = async () => {
    const success = await ChangeUsername();
    if (success) {
      // Optionally update user state if needed for native, web will have redirected by now
    }
    return success;
  };

  const changePhoneNumber = async () => {
    await clearUserCache(); // will get new user info after phone number change
    const success = await ChangePhoneNumber();
    if (success) {
      // Optionally update user state if needed for native, web will have redirected by now
    }
    return success;
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      isLoading, 
      isPerformingAuthAction,
      login, 
      logout, 
      changePassword, 
      changeUsername, 
      changePhoneNumber 
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);