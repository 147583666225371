import React, { lazy, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { AuthProvider, useAuth } from './context/AuthContext.js';
import { NewNotificationsContext } from './context/NewNotificationsContext';
import * as Linking from 'expo-linking';
import { updatePushSubscription } from './utils/Neighbor_utils.js';
import'./pageTranslation.js'
import LoadingPage from './components/LoadingPage.js';
import { Animated } from 'react-native-web';
import { RootSiblingParent } from 'react-native-root-siblings';
import { Text } from 'react-native';
import { customFetch } from './utils/Fetch_utils.js';
import FeatureFlagEnum from "./enums/FeatureFlagEnum";
import { SignalRProvider } from './context/SignalRContext.js';

// Overrides global fetch to redirect to landing page if user auth expires (http 401 code)
global.fetch = customFetch;

const LandingScreen = lazy(() => import('./screens/LandingScreen'));
const FindFoodScreen = lazy(() => import('./screens/FindFoodScreen'));
const AccountScreen = lazy(() => import('./screens/AccountScreen'));
const ProfileScreen = lazy(() => import('./screens/ProfileScreen'));
const NotificationScreen = lazy(() => import('./screens/NotificationScreen'));
const SettingsScreen = lazy(() => import('./screens/SettingsScreen'));
const MessageScreen = lazy(() => import('./screens/MessageScreen'));
const MessageDetailScreen = lazy(() => import('./screens/MessageDetailScreen'));
const NeighborHomeScreen = lazy(() => import('./screens/NeighborHomeScreen'));
const TermsOfUseScreen = lazy(() => import('./screens/TermsOfUseScreen'));
const PrivacyPolicyScreen = lazy(() => import('./screens/PrivacyPolicyScreen'));
const FullTermsOfUseScreen = lazy(() => import('./screens/FullTermsOfUse'));
const FullPrivacyPolicyScreen = lazy(() => import('./screens/FullPrivacyPolicy'));
const AboutScreen = lazy(() => import('./screens/AboutScreen'));
const FeedbackScreen = lazy(() => import('./screens/FeedbackScreen'));
const SurveysScreen = lazy(() => import('./screens/SurveysScreen'));
const DeliverySurveyScreen = lazy(() => import('./screens/DeliverySurveyScreen'));
const BenefitsScreen = lazy(() => import('./screens/BenefitsScreen.js'));
const EngageSurveyScreen = lazy(() => import('./screens/EngageSurveyScreen'));
const LanguageScreen = lazy(() => import('./screens/LanguageScreen'));
const MyFoodScreen = lazy(() => import('./screens/MyFoodScreen'));
const ResourceHubScreen = lazy(() => import('./screens/ResourceHubScreen'));
const OrderScreen = lazy(() => import('./screens/OrderScreen'));
const ReviewOrderScreen = lazy(() => import('./screens/ReviewOrderScreen'));

const Stack = createStackNavigator();

function AppContent() {
  const linking = {
    prefixes: [Linking.createURL('/'), 'https://test.nectarnow.com/', 'https://nectarnow.com/'],
  };
  const [newNotifications, setNewNotifications] = useState(0);
  const value = [newNotifications, setNewNotifications];
  const fadeRef = useRef(new Animated.Value(0)).current;
  const { user, isLoading } = useAuth();
  const isLoggedIn = !!user;

  
  useEffect(() => {
    const handleNewNotification = (event) => {
      console.log('MESSAGE:', event.data.msg);     
    }

    const handleServiceWorker = async () => {
      try {
        let registration = await navigator.serviceWorker.register('/service-worker.js');
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
        await registration.update();
        console.log('ServiceWorker updated successfully with scope: ', registration.scope);
        if(isLoggedIn){
          // I don't think we should be changing their push subscription unless they are logged in.
          // Otherwise, logging out would remove their push subscription.
          await updatePushSubscription();
        }

      } catch (error) {
        console.log('Error: ', error);
      }
    };

    if ('serviceWorker' in navigator)
      handleServiceWorker();

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleNewNotification);
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      Animated.timing(fadeRef, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true
      }).start();
    }
  }, [fadeRef, isLoading]);

  if (isLoading)
    return (<LoadingPage />); 

  return (
    <RootSiblingParent>
      <SignalRProvider>
        <NewNotificationsContext.Provider value={value}>
          <Animated.View style={{opacity: fadeRef, width: '100%'}}>   
            <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
              <Stack.Navigator screenOptions={{headerShown:false}}>
                {/* Logged Out Only */} 
                { ! isLoggedIn &&
                  <Stack.Screen name="Landing" component={LandingScreen} />
                }

                {/* Logged In Only */}
                {isLoggedIn &&
                  <>
                    {user.newUser == true
                      ? (
                        <>
                          <Stack.Screen name="Settings" component={SettingsScreen} />
                          <Stack.Screen name="Home" component={NeighborHomeScreen} />                     
                        </>
                      )
                      : (
                        <>
                          <Stack.Screen name="Home" component={NeighborHomeScreen} />
                          <Stack.Screen name="Settings" component={SettingsScreen} />
                        </>
                      )
                    }
                    <Stack.Screen name="Account" component={AccountScreen} />
                    <Stack.Screen name="Profile" component={ProfileScreen} />
                    <Stack.Screen name="Notifications" component={NotificationScreen} />               
                    <Stack.Screen name="Messages" component={MessageScreen} />
                    <Stack.Screen name="MessageDetail" component={MessageDetailScreen} />
                    <Stack.Screen name="Surveys" component={SurveysScreen} />
                    <Stack.Screen name="DeliverySurvey" component={DeliverySurveyScreen} />
                    <Stack.Screen name="Benefits" component={BenefitsScreen} />
                    <Stack.Screen name="EngageSurvey" component={EngageSurveyScreen} />
                    <Stack.Screen name="Language" component={LanguageScreen} />
                    <Stack.Screen name="MyFood" component={MyFoodScreen} />
                    <Stack.Screen name="ResourceHub" component={ResourceHubScreen} />
                    {
                      user?.features?.includes(FeatureFlagEnum.PantryEasy) == true &&
                      <>
                        <Stack.Screen name="OrderScreen" component={OrderScreen} />
                        <Stack.Screen name="ReviewOrder" component={ReviewOrderScreen} />
                      </>
                    }
                  </>
                }

                {/* Always Viewable */}
                <Stack.Screen name="About" component={AboutScreen} />
                <Stack.Screen name="FindFood" component={FindFoodScreen} />
                <Stack.Screen name="Feedback" component={FeedbackScreen} />
                <Stack.Screen name="TermsOfUse" component={TermsOfUseScreen} />
                <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicyScreen} />
                <Stack.Screen name="FullTermsOfUse" component={FullTermsOfUseScreen} />
                <Stack.Screen name="FullPrivacyPolicy" component={FullPrivacyPolicyScreen} />
              </Stack.Navigator>
            </NavigationContainer>
          </Animated.View> 
        </NewNotificationsContext.Provider>
      </SignalRProvider>
    </RootSiblingParent>
  );
}

export default function App() {
  return (
    // <App> separate from <AppContent> so we can use the <AuthProvider> in the <AppContent> component
    <AuthProvider>
        <AppContent />
    </AuthProvider>
  );
};